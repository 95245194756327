import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { ProjectContainer, Section, SEO, ProjectCard } from "components"

import StandardTemplate from "templates/StandardTemplate"

const PROJECTS_PAGE_QUERY = graphql`
  query ProjectsPageQuery {
    allProjectsJson {
      edges {
        node {
          name
          link
          type
          activeYears
          src {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

const IndexPage = () => (
  <StaticQuery
    query={PROJECTS_PAGE_QUERY}
    render={data => {
      const projects = data.allProjectsJson.edges

      return (
        <StandardTemplate>
          <SEO title="Projects" />
          <h1>Projects</h1>
          <p>
            Here are a few of the things I've been working on over the years.
          </p>
          <Section title={""}>
            <ProjectContainer>
              {projects.map(({ node }, index) => {
                return (
                  <ProjectCard
                    title={node.name}
                    subtitle={node.type}
                    publishedDate={node.activeYears}
                    key={"project" + index}
                    link={node.link}
                    imageSrcFluid={node.src.childImageSharp.fluid}
                  />
                )
              })}
            </ProjectContainer>
          </Section>
        </StandardTemplate>
      )
    }}
  />
)

export default IndexPage
